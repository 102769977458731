import React, { useCallback, useEffect, useState } from "react";
import { isFutureDate } from "../../../../common/utils/date";
import { settingStore } from "../../../template/store/setting";
import { Button } from "../../button/Button";
import {
  Bg,
  BtnContainer,
  CloseBtn,
  Container,
  CouponBulletList,
  CouponBulletListItem,
  CouponNotice,
  CouponTermOfUse,
  CouponTermOfUseWrapper,
  ModalRewardUsed,
  PointExchangeButton,
  PointExchangeUsed,
  SubTitle,
  Title,
} from "../style/style";
import useEarnedCampaignStore from "./../../../features/campaign/redux/earnedCampaign/store";

type ModalPointProps = {
  isShowPoint: boolean;
  title: string;
  subTitle?: string;
  contents: string;
  buttons?: {
    name: string;
    callback?: () => void;
    styling?: string;
    color?: string;
  }[];
  onClose: () => void;
  usedAt: string;
};

export const ModalPoint: React.FC<ModalPointProps> = (props) => {
  useEffect(() => {
    if (props.isShowPoint) {
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    }

    // クリーンアップ処理
    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, [props.isShowPoint]);
  const [used, setUsed] = useState<boolean>(false);
  // state
  const isAppliedPoint = useEarnedCampaignStore(
    (state) => state.isAppliedPoint,
  );

  const themeColor = settingStore((store) => store.theme_color);
  const close = useCallback(() => {
    //closePointModal();
    props.onClose();
    setUsed(false);
  }, []);

  const onApplyPoint = (callback: () => void) => {
    callback();
  };

  useEffect(() => {
    setUsed(isAppliedPoint);
  }, [isAppliedPoint]);

  if (!props.isShowPoint) {
    return null;
  }
  return (
    <div>
      <Bg isOpen={props.isShowPoint} />
      <Container isOpen={props.isShowPoint}>
        <div>
          <SubTitle>{props.subTitle}</SubTitle>
          <Title>{props.title}</Title>
          <CloseBtn
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        <CouponNotice>
          「ポイント反映」ボタンを押下すると ポイントを反映します
        </CouponNotice>

        {isFutureDate(props.usedAt) && (
          <PointExchangeButton used={!isFutureDate(props.usedAt)}>
            {props.buttons?.map((button, index) => {
              return (
                <Button
                  key={button.name}
                  text="ポイント反映"
                  styling="filled"
                  color={themeColor}
                  onClick={() =>
                    onApplyPoint(
                      button.callback
                        ? button.callback
                        : () => {
                            /**/
                          },
                    )
                  }
                />
              );
            })}
            {!isFutureDate(props.usedAt) && (
              <PointExchangeUsed>利用済み</PointExchangeUsed>
            )}
          </PointExchangeButton>
        )}

        {!isFutureDate(props.usedAt) && (
          <ModalRewardUsed>特典利用済み</ModalRewardUsed>
        )}

        <CouponTermOfUseWrapper>
          <CouponTermOfUse>ご利用にあたって</CouponTermOfUse>
          <CouponBulletList>
            {props.contents &&
              props.contents
                .split("\n")
                .map((content: string, i: number) => (
                  <CouponBulletListItem key={`point-content-${i}`}>
                    {content}
                  </CouponBulletListItem>
                ))}
          </CouponBulletList>
        </CouponTermOfUseWrapper>
        <BtnContainer>
          <Button
            text={"閉じる"}
            styling={"border"}
            color={"#000000"}
            onClick={() => close()}
          />
        </BtnContainer>
      </Container>
    </div>
  );
};
