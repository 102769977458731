import create from "zustand";
import { persist, redux } from "zustand/middleware";
import { getLineFriendsList } from "../../../core/http/httpClient";
import { log } from "../../hooks/useLogMiddleware";
import {
  clearLineFriendsData,
  fetchLineFriendsDataFailure,
  fetchLineFriendsDataStart,
  fetchLineFriendsDataSuccess,
  filterLineFriendsListData,
  setFilterLabelsFirst,
  setFilterLabelsSecond,
  setFilterLabelsThird,
} from "./actions";
import { initialState, reducer } from "./reducers";
import { LineFriends } from "./types";

const useLineFriendsStore = create(
  log(
    persist(redux(reducer, initialState), {
      name: "line_friends",
    }),
  ),
);

export const fetchLineFriendsList = async (tenant_code: string) => {
  const dispatch = useLineFriendsStore.getState().dispatch;
  dispatch(fetchLineFriendsDataStart());
  await getLineFriendsList(tenant_code)
    .then((res) => {
      dispatch(fetchLineFriendsDataSuccess(res.data));
      dispatch(setFirstLabels());
    })
    .catch((e) => {
      dispatch(fetchLineFriendsDataFailure(e));
    });
};

export const filterLineFriendsList = (
  label1: string,
  label2: string,
  label3: string,
) => {
  const state = useLineFriendsStore.getState();
  const lineFriendsList = state.lineFriendsList;
  const filteredLineFriendsList = lineFriendsList
    .filter((lineFriend: LineFriends) => {
      const validLabels = [label1, label2, label3].filter(
        (label): label is string => label !== "",
      );

      // すべてのラベルが `undefined` の場合、すべての要素を返す
      if (validLabels.length === 0) {
        return true;
      }
      return validLabels.every((label) => lineFriend.labels.includes(label));
    })
    .sort((a: LineFriends, b: LineFriends) => {
      const aNum = Number(a.displayed_num);
      const bNum = Number(b.displayed_num);

      // `display_num === 0` を最後に配置
      if (aNum === 0) return 1;
      if (bNum === 0) return -1;
      return Number(a.displayed_num) - Number(b.displayed_num);
    });
  state.dispatch(filterLineFriendsListData(filteredLineFriendsList));
};

export const setFirstLabels = () => {
  const state = useLineFriendsStore.getState();
  const lineFriendsList = state.lineFriendsList;
  const firstLabels = [
    ...new Set(
      lineFriendsList
        .map((lineFriend: LineFriends) => lineFriend.labels[0])
        .filter((label: string) => label !== ""),
    ),
  ].map((label) => ({
    name: label,
    value: label,
    count: lineFriendsList.filter(
      (lineFriend: LineFriends) => lineFriend.labels[0] === label,
    ).length,
  }));
  state.dispatch(setFilterLabelsFirst(firstLabels));
};

export const setSecondLabels = (selectedLabel1: string) => {
  const state = useLineFriendsStore.getState();
  const lineFriendsList = state.lineFriendsList;
  const secondLabels = [
    ...new Set(
      lineFriendsList
        .filter(
          (lineFriend: LineFriends) => lineFriend.labels[0] === selectedLabel1,
        )
        .map((lineFriend: LineFriends) => lineFriend.labels[1])
        .filter((label: string) => label !== ""),
    ),
  ].map((label) => ({
    name: label,
    value: label,
    count: lineFriendsList
      .filter(
        (lineFriend: LineFriends) => lineFriend.labels[0] === selectedLabel1,
      )
      .filter((lineFriend: LineFriends) => lineFriend.labels[1] === label)
      .length,
  }));
  useLineFriendsStore.getState().dispatch(setFilterLabelsSecond(secondLabels));
};

export const setThirdLabels = (
  selectedLabel1: string,
  selectedLabel2: string,
) => {
  const state = useLineFriendsStore.getState();
  const lineFriendsList = state.lineFriendsList;
  const thirdLabels = [
    ...new Set(
      lineFriendsList
        .filter(
          (lineFriend: LineFriends) =>
            lineFriend.labels[0] === selectedLabel1 &&
            lineFriend.labels[1] === selectedLabel2,
        )
        .map((lineFriend: LineFriends) => lineFriend.labels[2])
        .filter((label: string) => label !== ""),
    ),
  ].map((label) => ({
    name: label,
    value: label,
    count: lineFriendsList
      .filter(
        (lineFriend: LineFriends) =>
          lineFriend.labels[0] === selectedLabel1 &&
          lineFriend.labels[1] === selectedLabel2,
      )
      .filter((lineFriend: LineFriends) => lineFriend.labels[2] === label)
      .length,
  }));
  useLineFriendsStore.getState().dispatch(setFilterLabelsThird(thirdLabels));
};

export const clearLineFriendsList = () => {
  useLineFriendsStore.getState().dispatch(clearLineFriendsData());
};

export default useLineFriendsStore;
