import { QRCodeSVG } from "qrcode.react";
import React, { useCallback, useEffect, useState } from "react";
import Barcode from "react-barcode";
import { isFutureDate } from "../../../../common/utils/date";
import { Button } from "../../button/Button";
import {
  Bg,
  BtnContainer,
  CloseBtn,
  Container,
  CouponBulletList,
  CouponBulletListItem,
  CouponNotice,
  CouponTermOfUse,
  CouponTermOfUseWrapper,
  GlobalStyle,
  ModalRewardUsed,
  QRContainer,
  StyledBarCode,
  SubTitle,
  Title,
} from "../style/style";

type ModalCouponProps = {
  isShowCoupon: boolean;
  title: string;
  subTitle?: string;
  janCode: string;
  contents: string;
  codeType: "barcode" | "none" | "qr";
  buttons?: {
    name: string;
    callback?: () => void;
    styling?: string;
    color?: string;
  }[];
  onClose: () => void;
  usedAt: string;
};

export const ModalCoupon: React.FC<ModalCouponProps> = (props) => {
  const [width, setWidth] = useState<number>(0);
  const close = useCallback(() => {
    props.onClose();
  }, []);

  useEffect(() => {
    setWidth(window.innerWidth * 0.3);
  }, []);

  if (!props.isShowCoupon) {
    return null;
  }
  return (
    <div>
      <GlobalStyle isModalOpen={props.isShowCoupon} />
      <Bg isOpen={props.isShowCoupon} />
      <Container isOpen={props.isShowCoupon}>
        <div>
          <SubTitle>{props.subTitle}</SubTitle>
          <Title>{props.title}</Title>
          <CloseBtn
            onClick={() => {
              close();
            }}
          >
            <img src="/assets/ui/modal/close.svg" alt="close" />
          </CloseBtn>
        </div>
        <CouponNotice>
          レジ精算時に画面のバーコードを
          <br />
          店員へご提示ください
        </CouponNotice>
        {!isFutureDate(props.usedAt) && (
          <ModalRewardUsed>特典利用済み</ModalRewardUsed>
        )}
        {isFutureDate(props.usedAt) && (
          <StyledBarCode>
            {props.codeType === "barcode" && (
              <Barcode
                value={props.janCode || "00000000"}
                height={50}
                fontSize={14}
                width={props.janCode.length > 20 ? 0.8 : 2}
              />
            )}

            {props.codeType === "qr" && (
              <QRContainer>
                <QRCodeSVG
                  value={props.janCode || "00000000"}
                  height={150}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: `${width}px`,
                    margin: "0 15px",
                  }}
                />
              </QRContainer>
            )}
          </StyledBarCode>
        )}

        <CouponTermOfUseWrapper>
          <CouponTermOfUse>ご利用にあたって</CouponTermOfUse>
          <CouponBulletList>
            <CouponBulletListItem>
              {props.contents &&
                props.contents
                  .split("\n")
                  .map((content: string, i: number) => (
                    <CouponBulletListItem key={`coupon-content-${i}`}>
                      ・{content}
                    </CouponBulletListItem>
                  ))}
            </CouponBulletListItem>
          </CouponBulletList>
        </CouponTermOfUseWrapper>
        {props.buttons?.map((button, index) => {
          return (
            <div
              key={`custom-button-${index}`}
              style={{ marginBottom: "10px" }}
            >
              <Button
                key={button.name}
                text={button.name}
                onClick={
                  button.callback
                    ? (button.callback as any)
                    : () => {
                        /* nothing to do */
                      }
                }
              />
            </div>
          );
        })}
        <BtnContainer>
          <Button
            text={"閉じる"}
            styling={"border"}
            color={"#000000"}
            onClick={() => close()}
          />
        </BtnContainer>
      </Container>
    </div>
  );
};
