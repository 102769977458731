import styled from "styled-components";

type Props = {
  imageRatio?: number;
};

export const Header = styled.h3`
  position: fixed;
  top: 0;
  z-index: 1999;
  margin: 25px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.25em;
  height: 60px;
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
  height: 100%;
`;
export const ImageContainer = styled.div<Props>`
  margin-top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  /*max-height: 100svh;*/
  ${({ imageRatio }) => (imageRatio ? imageRatio < 1 && `width: 100%;` : "")}
`;
export const Image = styled.img<Props>`
  z-index: 1000;
  max-height: 100vh;
  width: 100%;
  object-fit: contain;
  pointer-events: auto;
`;
export const ButtonReturn = styled.div`
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  font-size: 16px;
  line-height: 16px;
  margin: 5px 0 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 3px;
  z-index: 9999;
  pointer-events: auto;
  vertical-align: top;
  -webkit-tap-highlight-color: transparent;
`;
export const ButtonText = styled.span`
  line-height: 16px;
  font-size: 16px;
  display: inline-block;
  padding: 0 5px 2px 0;
`;
