import React, { useCallback, useEffect, useState } from "react";
import { LINE } from "../../../common/icons/LINE";
import { isValidFilterLabels } from "../../../common/utils/lineFriends";
import useLineFriendsStore, {
  filterLineFriendsList,
  setSecondLabels,
  setThirdLabels,
} from "../../../lib/lineFriends/redux/store";
import { LineFriends } from "../../../lib/lineFriends/redux/types";
import { FilterResult } from "../../features/line_friends/components/FilterResult";
import { SelectArea } from "../../features/line_friends/components/SelectArea";
import {
  Breadcrumb,
  Contents,
  HeaderContainer,
  Message,
  Title,
} from "../../features/line_friends/style/select-area";
import { Labels } from "../../features/line_friends/types";
import { useMemberRegister } from "../../features/member_registration/hooks/useMemberRegister";
import { HeaderTemplate } from "../../template";
import { settingStore } from "../../template/store/setting";
import { Base } from "../../template/style";
import { Button } from "../../ui/button/Button";
import { LineFooter } from "../../ui/footer/LineFooter";

export const LineFriend: React.FC = () => {
  const lineFriend = settingStore((state) => state.line_settings);
  const { loginWithRegisterData } = useMemberRegister();
  const lineFriendList = useLineFriendsStore((state) => state.lineFriendsList);
  const filteredLineFriendsList = useLineFriendsStore(
    (state) => state.filteredLineFriendsList,
  );
  const [filterScreen, setFilterScreen] = useState<
    "start" | "result" | "filter"
  >("start");
  const [buttonText, setButtonText] = useState<string>("次へ");
  const [subButtonText, setSubButtonText] = useState<string>("");
  const [selectedLabels, setSelectedLabels] = useState<Labels>({
    label1: "",
    label2: "",
    label3: "",
  });
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [lineFriendUrl, setLineFriendUrl] = useState<string>("");
  const [isFirstLabel, setIsFirstLabel] = useState<boolean>(true);
  const isLabelEmpty = Object.values(selectedLabels).every(
    (value) => value === "",
  );
  const FIRST_LABELS = useLineFriendsStore((state) => state.firstLabels);
  const SECOND_LABELS = useLineFriendsStore((state) => state.secondLabels);
  const THIRD_LABELS = useLineFriendsStore((state) => state.thirdLabels);
  useEffect(() => {
    const { label1, label2, label3 } = selectedLabels;
    filterLineFriendsList(label1, label2, label3);
    setSecondLabels(label1);
    setThirdLabels(label1, label2);
  }, [filterScreen, selectedLabels]);

  useEffect(() => {
    setIsFirstLabel(FIRST_LABELS.length !== 0);
  }, [FIRST_LABELS]);

  useEffect(() => {
    const selectedLINEFriend = filteredLineFriendsList.find(
      (lineFriend: LineFriends) => lineFriend.id === Number(selectedValue),
    );
    setLineFriendUrl(selectedLINEFriend?.line_friend_url || "");
  }, [filteredLineFriendsList, selectedValue, lineFriendUrl]);

  const onNextAddLINE = useCallback(() => {
    if (filterScreen === "start") {
      setFilterScreen("result");
      setButtonText("LINEで友だち追加へ");
      setSubButtonText("友だち追加せずに次へ");
    }
    if (filterScreen === "filter") {
      setFilterScreen("result");
      setButtonText("LINEで友だち追加へ");
      setSubButtonText("友だち追加せずに次へ");
    }
    if (filterScreen === "result") {
      window.open(lineFriendUrl, "_blank", "noopener noreferrer");
      loginWithRegisterData();
    }
  }, [filterScreen, lineFriendUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const onNextWithoutAddLINE = useCallback(() => {
    if (filterScreen === "result") {
      loginWithRegisterData();
    }
  }, [filterScreen]);

  const onFilter = useCallback(() => {
    setFilterScreen("filter");
    setButtonText("選択した条件で絞り込む");
    setSubButtonText("");
    setSelectedValue("");
    setSelectedLabels({ label1: "", label2: "", label3: "" });
  }, []);

  return (
    <Base>
      <HeaderTemplate>
        <HeaderContainer>
          {filterScreen === "filter" && <Title>絞り込み</Title>}
          {filterScreen !== "filter" && <Title>LINE友だち追加</Title>}
        </HeaderContainer>
        <Contents>
          {filterScreen === "start" && (
            <>
              <LINE width={75} height={75} />
              <Message>{lineFriend.expository_text}</Message>
            </>
          )}
          {filterScreen === "filter" && (
            <SelectArea
              selectedLabels={selectedLabels}
              firstLabels={FIRST_LABELS}
              secondLabels={SECOND_LABELS}
              thirdLabels={THIRD_LABELS}
              onFilter={setSelectedLabels}
            />
          )}
          {filterScreen === "result" && (
            <>
              {isValidFilterLabels(lineFriend?.filter_labels) &&
                isFirstLabel && (
                  <>
                    <Button
                      styling="border"
                      text="絞り込み条件の変更"
                      onClick={onFilter}
                    />
                    <Breadcrumb>
                      絞り込み条件：
                      {isLabelEmpty
                        ? "なし"
                        : `${selectedLabels.label1} > ${selectedLabels.label2} > ${selectedLabels.label3}`}
                    </Breadcrumb>
                  </>
                )}
              {filteredLineFriendsList.length !== 0 && (
                <FilterResult
                  lineFriendList={filteredLineFriendsList}
                  onChange={handleChange}
                  selectedValue={selectedValue}
                  isModal={false}
                />
              )}
              {filteredLineFriendsList.length === 0 && (
                <div>検索結果がありません</div>
              )}
            </>
          )}
        </Contents>
      </HeaderTemplate>
      <LineFooter
        onClickButton={onNextAddLINE}
        onClickSecondaryButton={onNextWithoutAddLINE}
        filterScreen={filterScreen}
        buttonText={buttonText}
        secondaryButtonText={subButtonText}
        buttonDisabled={filterScreen === "result" && !selectedValue}
        isRequiredLineFriend={lineFriend.is_line_friend_required}
      />
    </Base>
  );
};
