import React from "react";
import { settingStore } from "../../../template/store/setting";
import { Selection } from "../../../ui/selection/Selection";
import { SelectionArea } from "../style/select-area";
import { Labels } from "../types";

type SelectionOption = {
  name: string;
  value: string;
};

type Props = {
  selectedLabels: Labels;
  firstLabels: SelectionOption[];
  secondLabels: SelectionOption[];
  thirdLabels: SelectionOption[];
  onFilter: (updateLabels: (prevLabels: Labels) => Labels) => void;
};

export const SelectArea: React.FC<Props> = ({
  selectedLabels,
  onFilter,
  firstLabels,
  secondLabels,
  thirdLabels,
}) => {
  const updateLabel = (key: keyof Labels, value: string) => {
    onFilter((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const lineSettings = settingStore((state) => state.line_settings);
  const filterLabels = lineSettings?.filter_labels;

  return (
    <SelectionArea>
      {filterLabels[0] !== "" && (
        <Selection
          name="area"
          label={filterLabels[0]}
          placeholder="選択してください"
          options={firstLabels}
          onSelection={(val: string) => {
            updateLabel("label1", val.toString());
          }}
          classes={{ width: "auto", margin: "5px auto" }}
          selectClasses={{ margin: "8px 0" }}
          value={selectedLabels.label1 ? selectedLabels.label1 : undefined}
        />
      )}
      {filterLabels[0] !== "" && filterLabels[1] !== "" && (
        <Selection
          name="prefecture"
          label={filterLabels[1]}
          placeholder="選択してください"
          options={secondLabels}
          onSelection={(val: string) => {
            updateLabel("label2", val.toString());
          }}
          classes={{ width: "auto", margin: "5px auto" }}
          selectClasses={{ margin: "8px 0" }}
          value={selectedLabels.label2 ? selectedLabels.label2 : undefined}
          noSelection={selectedLabels.label1 ? false : true}
        />
      )}
      {filterLabels[0] !== "" &&
        filterLabels[1] !== "" &&
        filterLabels[2] !== "" && (
          <Selection
            name="district"
            label={filterLabels[2]}
            placeholder="選択してください"
            options={thirdLabels}
            onSelection={(val: string) => {
              updateLabel("label3", val.toString());
            }}
            classes={{ width: "auto", margin: "5px auto" }}
            selectClasses={{ margin: "8px 0" }}
            value={selectedLabels.label3 ? selectedLabels.label3 : undefined}
            noSelection={selectedLabels.label2 ? false : true}
          />
        )}
    </SelectionArea>
  );
};
